/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
  min-height: 100%;
  .layout-boxed & {
    height: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Layout */
.wrapper {
  .clearfix();
  min-height: 100%;
  position: relative;
  overflow: hidden;
  .layout-boxed & {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: relative;
  }
}

.layout-boxed {
  background: url('@{boxed-layout-bg-image-path}') repeat fixed;
}

/*
 * Content Wrapper - contains the main content
 * ```.right-side has been deprecated as of v2.0.0 in favor of .content-wrapper  ```
 */
.content-wrapper,
.right-side,
.main-footer {
  //Using disposable variable to join statements with a comma
  @transition-rule: @transition-speed @transition-fn,
  margin @transition-speed @transition-fn;
  .transition-transform(@transition-rule);
  margin-left: @sidebar-width;
  z-index: 820;
  //Top nav layout
  .layout-top-nav & {
    margin-left: 0;
  }
  @media (max-width: @screen-xs-max) {
    margin-left: 0;
  }
  //When opening the sidebar on large screens
  .sidebar-collapse & {
    @media (min-width: @screen-sm) {
      margin-left: 0;
    }
  }
  //When opening the sidebar on small screens
  .sidebar-open & {
    @media (max-width: @screen-xs-max) {
      .translate(@sidebar-width, 0);
    }
  }
}

.content-wrapper,
.right-side {
  min-height: 100%;
  background-color: @body-bg;
  z-index: 800;
}

.main-footer {
  background: #F3F3F3;
  padding: 15px;
  color: #444;
  border-top: 1px solid @gray;
}

/* Fixed layout */
.fixed {
  .main-header,
  .main-sidebar,
  .left-side {
    position: fixed;
  }
  .main-header {
    top: 0;
    right: 0;
    left: 0;
  }
  .content-wrapper,
  .right-side {
    padding-top: 50px;
    @media (max-width: @screen-header-collapse) {
      padding-top: 100px;
    }
  }
  &.layout-boxed {
    .wrapper {
      max-width: 100%;
    }
  }
}

body.hold-transition {
  .content-wrapper,
  .right-side,
  .main-footer,
  .main-sidebar,
  .left-side,
  .main-header > .navbar,
  .main-header .logo {
    /* Fix for IE */
    .transition(none);
  }
}

/* Content */
.content {
  min-height: 250px;
  padding: 15px;
  .container-fixed(@grid-gutter-width);
}

.content.no-padding{padding:0px !important;}
.content.no-padding .panel{margin-bottom:0px;}

/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Nunito Sans', sans-serif;
}

/* General Links */
a {
  color: @link-color;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: @link-hover-color;
}

/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;

  > small {
    color: #666;
    display: block;
    margin-top: 5px;
  }
}

.border-circle{
    border-radius: 50% !important;
}
.uppercase {
    text-transform: uppercase;
}
.strong{
    font-weight: bold;
}

.preview img{
    max-width:100px;
    max-height:100px;
}

/* margin and padding */

// ............................................................
// .for

.for(@i, @n) {.-each(@i)}
.for(@n)     when (isnumber(@n)) {.for(1, @n)}
.for(@i, @n) when not (@i = @n)  {
    .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

// ............................................................
// .for-each

.for(@array)   when (default()) {.for-impl_(length(@array))}
.for-impl_(@i) when (@i > 1)    {.for-impl_((@i - 1))}
.for-impl_(@i) when (@i > 0)    {.-each(extract(@array, @i))}

@dists: 30, 25, 20, 15, 10, 5, 0;

.for(@dists); .-each(@dist) {
    &.m@{dist} {margin: unit(@dist, px)}
    &.mt@{dist} {margin-top: unit(@dist, px)}
    &.mr@{dist} {margin-right: unit(@dist, px)}
    &.mb@{dist} {margin-bottom: unit(@dist, px)}
    &.ml@{dist} {margin-left: unit(@dist, px)}
    &.p@{dist} {padding: unit(@dist, px)}
    &.pt@{dist} {padding-top: unit(@dist, px)}
    &.pr@{dist} {padding-right: unit(@dist, px)}
    &.pb@{dist} {padding-bottom: unit(@dist, px)}
    &.pl@{dist} {padding-left: unit(@dist, px)}
}

/* margin and padding ends */

/*bg*/
.bg-danger {
    background-color: #f05050;
    color: #fff;
}
.bg-white{
    background: #fff !important;
}
.bg-off-white {
    background:#f2f4f6;
}
.bg-info {
    background-color: #1ccacc;
    color: #bef5f6;
}
.bg-primary{
    background-color: #48b0f7;
    color: #fff;
}
.bg-success{
    background: #03BBAA;
    color: #fff;
}